





















































































































.p-register-consumer {
  > .item {
    display: flex;
    // padding: 10px;
    margin: 20px 15px;
    align-items: center;
    background-color: #ffffff;
    > .v-input {
      flex: 1;
      font-size: 16px;
      border-radius: 0px;
      // input {
      //   border-radius: 0px;
      // }
    }
    > .vercode {
      color: #41ac76;
      margin-left: 10px;
      border-radius: 16px;
      border-color: #41ac76;
    }
    > .login {
      width: 100%;
      display: block;
      font-size: 16px;
      line-height: 32px;
      border-radius: 16px;
    }
  }
}
