.p-register-consumer > .item {
  display: flex;
  margin: 20px 15px;
  align-items: center;
  background-color: #ffffff;
}
.p-register-consumer > .item > .v-input {
  flex: 1;
  font-size: 16px;
  border-radius: 0px;
}
.p-register-consumer > .item > .vercode {
  color: #41ac76;
  margin-left: 10px;
  border-radius: 16px;
  border-color: #41ac76;
}
.p-register-consumer > .item > .login {
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 32px;
  border-radius: 16px;
}
